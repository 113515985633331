<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPartners(true)" />
                </div>
                <div class="col-auto">
                    <el-button type="primary" @click="showPartnerDialog(null)">添加</el-button>
                </div>
            </div>
            <el-table :data="partners.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="姓名/企业名称" sortable />
                <el-table-column prop="registeredUser.idCard" label="身份证号/统一社会信用代码" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column prop="regionName" label="行政区划" sortable />
                <el-table-column label="操作" fixed="right" width="100">
                    <template #default="scope">
                        <el-button type="text" @click="showPartnerDialog(scope.row)">修改</el-button>
                        <el-button type="text" class="text-danger" @click="deletePartner(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="partners.totalRecords"
                :current-page="partnersPagination.currentPage" :page-size="partnersPagination.pageSize" class="mt-3"
                @current-change="partnersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="partnerDialogVisible" title="土地来源方" :close-on-click-modal="false" width="800px">
            <el-form>
                <el-form-item label="注册用户" class="form-item-required">
                    <el-input :value="partner.registeredUser.name" readonly placeholder="注册用户">
                        <template #append v-if="partner.id==undefined">
                            <el-button @click="isEnterprise=false;selectRegisteredUserDialogVisible=true">
                                选择个人用户
                            </el-button>
                            <el-button @click="isEnterprise=true;selectRegisteredUserDialogVisible=true">
                                选择企业用户
                            </el-button>
                        </template>
                    </el-input>
                </el-form-item>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="联系人" class="form-item-required">
                            <el-input v-model="partner.contactName" placeholder="联系人" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="联系电话" class="form-item-required">
                            <el-input v-model="partner.contactPhone" placeholder="联系电话" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="行政区划" class="form-item-required">
                            <el-input :value="partner.regionName" readonly placeholder="行政区划">
                                <template #append>
                                    <el-button @click="selectRegionDialogVisible=true">选择</el-button>
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="地址" class="form-item-required">
                            <el-input v-model="partner.address" placeholder="地址" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="开户行" class="form-item-required">
                            <el-input v-model="partner.openingBank" placeholder="开户行" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="开户名" class="form-item-required">
                            <el-input v-model="partner.accountName" placeholder="开户名" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row :gutter="8">
                    <el-col :span="12">
                        <el-form-item label="行号" class="form-item-required">
                            <el-input v-model="partner.bankNumber" placeholder="行号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="银行账号" class="form-item-required">
                            <el-input v-model="partner.accountNumber" placeholder="银行账号" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item label="备注">
                    <el-input v-model="partner.remark" placeholder="备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="savePartner">确定</el-button>
                <el-button @click="partnerDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>

        <select-registered-user-dialog v-model="selectRegisteredUserDialogVisible" :is-enterprise="isEnterprise"
            @select="selectRegisteredUser" />

        <select-region-dialog v-model="selectRegionDialogVisible"
            @select="partner.regionId=$event.id;partner.regionName=$event.name" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                partners: {},
                partnersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                partner: { registeredUser: {} },
                isEnterprise: true,
                partnerDialogVisible: false,
                selectRegisteredUserDialogVisible: false,
                selectRegionDialogVisible: false,
            };
        },
        methods: {
            async loadPartners(resetPage = false) {
                if (resetPage) {
                    this.partnersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Partner/GetPartners', {
                    params: {
                        companyId: this.$store.state.companyId,
                        key: this.key,
                        pageIndex: this.partnersPagination.currentPage - 1,
                        pageSize: this.partnersPagination.pageSize,
                    }
                });
                this.partners = response.data;
            },
            partnersPaginationCurrentChange(page) {
                this.partnersPagination.currentPage = page;
                this.loadPartnerss();
            },
            showPartnerDialog(partner) {
                if (partner) {
                    this.partner = JSON.parse(JSON.stringify(partner));
                }
                else {
                    this.partner = { registeredUser: {} };
                }
                this.partnerDialogVisible = true;
            },
            selectRegisteredUser(user) {
                this.partner.registeredUser.id = user.id;
                this.partner.registeredUser.name = user.name;
            },
            async savePartner() {
                if (this.partner.id == undefined) {
                    await this.$axios.post('/api/Partner/AddPartner', {
                        registeredUserId: this.partner.registeredUser.id,
                        contactName: this.partner.contactName,
                        contactPhone: this.partner.contactPhone,
                        regionId: this.partner.regionId,
                        address: this.partner.address,
                        openingBank: this.partner.openingBank,
                        accountName: this.partner.accountName,
                        bankNumber: this.partner.bankNumber,
                        accountNumber: this.partner.accountNumber,
                        remark: this.partner.remark,
                    });
                    this.partnerDialogVisible = false;
                    this.loadPartners();
                }
                else {
                    await this.$axios.post('/api/Partner/UpdatePartner', {
                        id: this.partner.id,
                        contactName: this.partner.contactName,
                        contactPhone: this.partner.contactPhone,
                        regionId: this.partner.regionId,
                        address: this.partner.address,
                        openingBank: this.partner.openingBank,
                        accountName: this.partner.accountName,
                        bankNumber: this.partner.bankNumber,
                        accountNumber: this.partner.accountNumber,
                        remark: this.partner.remark,
                    });
                    this.partnerDialogVisible = false;
                    this.loadPartners();
                }
            },
            async deletePartner(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Partner/DeletePartner', { id: id });
                this.loadPartners();
            },
        },
        created() {
            this.loadPartners();
        },
    };
</script>